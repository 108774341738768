<template>
  <div
    id="click_log_page"
    class="card"
  >
    <b-card-header>
      <ps-filters
        v-model="filterValues"
        :filter-settings="filterSettings"
        :show-export="true"
        @search="search"
        @exportToCSV="exportToCSV"
      />
    </b-card-header>
    <div class="table-responsive mb-0">
      <table
        role="table"
        class="table b-table table-striped table-hover"
      >
        <thead
          role="rowgroup"
          class=""
        >
          <tr
            role="row"
            class=""
          >
            <th
              role="columnheader"
              aria-colindex="1"
              :aria-sort="arialSort('hash')"
              @click="sortCol('hash')"
            >
              Hash
            </th>
            <th
              role="columnheader"
              aria-colindex="2"
              :aria-sort="arialSort('external_user_id')"
              @click="sortCol('external_user_id')"
            >
              Your User ID
            </th>
            <th
              role="columnheader"
              aria-colindex="2"
            >
              App
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('length_of_interview')"
              @click="sortCol('length_of_interview')"
            >
              LOI
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('result')"
              @click="sortCol('result')"
            >
              Result
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('publisher_payout')"
              @click="sortCol('publisher_payout')"
            >
              Payout
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('reward')"
              @click="sortCol('reward')"
            >
              Reward
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('created_at')"
              @click="sortCol('created_at')"
            >
              Datetime
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template v-for="(rowTr, indextr) in items">
            <tr
              :key="indextr"
              :class="rowTr.class"
            >
              <td>
                {{ rowTr.hash }}
              </td>
              <td>
                {{ rowTr.external_user_id }}
              </td>
              <td>
                <router-link :to="{ name: 'app-edit', params: { appHash: rowTr.app_hash }}">
                  {{ rowTr.app_name }}
                </router-link>
              </td>
              <td>
                {{ rowTr.loi | Hms('') }}
              </td>
              <td :class="`text-${rowTr.statusClass}`">
                {{ rowTr.status }}
              </td>
              <td class="text-success">
                <template v-if="rowTr.publisher_payout !== 0">
                  {{ rowTr.publisher_payout | formatCurrency }}
                </template>
              </td>
              <td>
                {{ rowTr.user_reward }}
              </td>
              <td>
                {{ rowTr.created_at | dotDateTimeShort }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-card-footer>
      <b-row>
        <b-col
          v-show="items.length > 0"
          cols="12"
        >
          <pagination-and-table-info
            :data="paginationData"
            @change="handleChangePage($event)"
          />
        </b-col>
        <b-col
          v-show="items.length === 0"
          cols="12"
          style="font-weight: 500; text-align: center"
        >
          No data
        </b-col>
      </b-row>
    </b-card-footer>
  </div>
</template>

<script>
import PaginationAndTableInfo from '@ps_main/custom-components/PaginationAndTableInfo.vue'
import PsFilters from '@ps_main/custom-components/PsFilters.vue'

export default {
  components: {
    PaginationAndTableInfo,
    PsFilters,
  },
  data() {
    return {
      url: '/api/logs/click-log',
      params: {
        page: 1,
        orderBy: 'id',
        itemPerPage: 500,
        orderDirection: 'desc',
        initFilter: 0,
      },
      items: [],
      responseData: null,
      filterValues: {},
      filterSettings: {
        dateRange: {
          type: 'daterange',
          label: 'From - To',
          class: 'mr-2 mb-0',
          style: '',
          data: [],
          defaultValue: {
            fromDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            toDate: new Date(),
          },
          postKey: 'dateRange',
          'label-cols': 2,
        },
        app: {
          type: 'select',
          label: 'App name',
          class: 'mr-2 mb-0',
          style: 'width: 320px;',
          data: [],
          defaultData: { value: 0, text: 'All apps' },
          defaultValue: 0,
          postKey: 'app',
          'label-cols': 4,
        },
        status: {
          type: 'select',
          label: 'Status',
          class: 'mr-2 mb-0',
          style: 'width: 320px;',
          data: [],
          defaultData: { value: 0, text: 'All statuses' },
          defaultValue: 0,
          postKey: 'status',
          'label-cols': 3,
        },
      },
    }
  },
  computed: {
    paginationData() {
      return this.laravelPaginationPages(this.responseData)
    },
  },
  created() {
    Object.keys(this.filterSettings).forEach(property => {
      if (
        Array.isArray(this.filterSettings[property].data)
          && this.filterSettings[property].defaultData !== undefined
      ) {
        this.filterSettings[property].data.push(this.filterSettings[property].defaultData)
      }
    })
  },
  methods: {
    handleChangePage(page) {
      this.params.page = page
      this.search()
    },
    sortCol(fieldName) {
      if (this.params.orderBy === fieldName) {
        this.params.orderDirection = this.params.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.orderBy = fieldName
        this.params.orderDirection = 'asc'
      }
      this.search()
    },
    arialSort(sortFieldName) {
      if (this.params.orderBy === sortFieldName) {
        return this.params.orderDirection === 'asc'
          ? 'ascending'
          : 'descending'
      }
      return 'none'
    },
    search() {
      this.showLoading()
      this.$http
        .get(`${this.url}/get`, {
          params: {
            ...this.params,
            ...this.filterValues,
          },
        })
        .then(response => {
          this.hideLoading()
          const responseData = response.data
          this.items = responseData.data
          this.responseData = responseData
          this.initiateFilters(responseData)
        })
        .catch(errors => {
          this.alertError(errors.response.data)
          this.hideLoading()
        })
    },
    initiateFilters(responseData) {
      if (
        this.params.initFilter === 0
          && typeof responseData.filterData !== 'undefined'
      ) {
        Object.keys(responseData.filterData).forEach(property => {
          if (typeof this.filterSettings[property] === 'object') {
            if (this.filterSettings[property].defaultData !== undefined) {
              this.filterSettings[property].data = [
                ...[this.filterSettings[property].defaultData],
                ...responseData.filterData[property],
              ]
            } else {
              this.filterSettings[property].data = responseData.filterData[property]
            }
          }
        })

        this.params.initFilter = 1
      }
    },
    exportToCSV() {
      this.isBusy = true
      this.showLoading()
      this.$http
        .get(`${this.url}/export`, {
          params: {
            ...this.params,
            ...this.filterValues,
            only_file_name: true,
          },
        })
        .then(response => {
          const filename = response.data

          this.$http
            .get(`${this.url}/export`, {
              params: {
                ...this.params,
                ...this.filterValues,
              },
              responseType: 'blob',
            })
            .then(responseExport => {
              this.hideLoading()

              const url = window.URL.createObjectURL(new Blob([responseExport.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', filename)
              document.body.appendChild(link)
              link.click()

              this.isBusy = false
            })
            .catch(errors => {
              if (typeof errors.response === 'object') {
                if (typeof errors.response.data.errors === 'object') {
                  this.alertError(errors.response.data.errors)
                } else {
                  this.alertError(errors.response.data)
                }
              } else {
                this.alertError(errors.message)
              }

              this.hideLoading()
              this.isBusy = false
            })
        })
        .catch(errors => {
          if (typeof errors.response === 'object') {
            if (typeof errors.response.data.errors === 'object') {
              this.alertError(errors.response.data.errors)
            } else {
              this.alertError(errors.response.data)
            }
          } else {
            this.alertError(errors.message)
          }

          this.hideLoading()
          this.isBusy = false
        })
    },
  },
}
</script>
